
const {
    VUE_APP_APIKEY: APIKEY,
    VUE_APP_SERVER_URL: VUE_APP_SERVER_URL,
} = process.env

const fetchOptions = {
    credentials: 'include',
    headers: !APIKEY ? undefined : {
      'Authorization': 'Bearer ' + APIKEY
    },
  }


const fetchAlbums = () => {
return fetch(VUE_APP_SERVER_URL.concat('/api/albums?populate=*'), fetchOptions)
    .then((response) => response.json());
}

const fetchTourDates = () => {
    console.log("Fetch tour dates")
    return fetch(VUE_APP_SERVER_URL.concat('/api/tour-dates'), fetchOptions)
        .catch(handleError)
        .then(handleResponse);
    }

const handleError = (error) => {
    if (error.name === 'AbortError') {
        return Promise.reject(error) // if abort, pass it to through
    }
    else {
        console.log('Failed to get data:', error)
        return Promise.reject('Failed to get data!')
    }
    }

const handleResponse = (resp) => {
    if (!resp) return
    if (!resp.ok) throw resp // not status range 200-299
    
    if (process.env.NODE_ENV !== 'production') {
        resp.clone().json()
        .then(data => console.log('API:', data))
        .catch(() => console.warn('API:', resp))
    }
    
    return resp.json()
    }


export default {
    fetchTourDates,
    fetchAlbums
}
