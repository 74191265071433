import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        data: {},
        input: '',
        loading: null
      },

    getters: {
        albums: state => state.data.data || [],
        tour_dates: state => state.data.data || [],
    },

    mutations: {
        setData: (state, payload) => state.data = payload || {},
        setLoading: (state, payload) => state.loading = payload || null
      },

    actions: {
        fetchAlbums: async ({ commit }) => {
            try {
            commit('setLoading', true)
            const data = await api.fetchAlbums()

            commit('setData', data)
            commit('setLoading', false)
            }
            catch (error) {
            commit('setLoading', false)
            console.log('error')
            }
        },
        fetchTourDates: async ({ commit }) => {
            try {
            commit('setLoading', true)
            const data = await api.fetchTourDates()
            commit('setData', data)
            commit('setLoading', false)
            }
            catch (error) {
            commit('setLoading', false)
            console.log('error')
            }
        }
    
}
})
