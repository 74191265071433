import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //{
  //  path: '/',
  //  name: 'Home',
  //  component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  //},
  {
    path: '/',
    name: 'Music',
    component: () => import(/* webpackChunkName: "home" */ '../views/Music.vue')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import(/* webpackChunkName: "home" */ '../views/Live.vue')
  },
  {
    path: '/music',
    name: 'Music',
    component: () => import(/* webpackChunkName: "home" */ '../views/Music.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "home" */ '../views/Contact.vue')
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
